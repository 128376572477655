.container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-return-dashboard {
  padding-top: 1.5rem;
}

.message-text {
  color: var(--ion-color-light-contrast);
}

.first-message {
  max-width: 50%;
  text-align: center;
  font-size: 14px;
}

.info-supp-message {
  font-size: 12px;
}

.container-content-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 35%;
  text-align: center;
}

.title-thanks {
  font-size: 4rem;
  font-weight: bold;
  color: var(--ion-color-light-contrast);
}

@media screen and (max-width: 768px) {
  .container-content-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 50%;
    text-align: center;
  }

  .first-message {
    max-width: 70%;
    text-align: center;
    font-size: 14px;
  }
}